<template>
  <div>
    <Table
      :actions="actions"
      :items="$store.getters['withdrawalAccount/tableData']"
      :detail="$store.getters['withdrawalAccount/detailData']"
      :fields="$store.getters['withdrawalAccount/tableFields']"
      :loading="$store.state.withdrawalAccount.loadingTable"
      :filter="$store.state.withdrawalAccount.tableFilter"
      :sort="$store.state.withdrawalAccount.tableSort"
      :sort-options="sortOptions"
      :pagination="$store.state.withdrawalAccount.tablePagination"
      :keyword="$store.state.withdrawalAccount.tableKeyword"
      search-placeholder="Search email, username"
      @load="loadData"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @resetDetail="resetDetail"
      @paginate="paginate"
      @search="search"
    />
    <b-modal
      v-model="banWithdrawalAccountVisible"
      title="Ban Withdrawal Account"
      hide-footer
    >
      <p>{{ `This withdrawal account will banned. Are you sure? ` }}</p>
      <FormBuilder
        :fields="setBanField"
        :loading-submit="$store.state.withdrawalAccount.loadingSubmit"
        submit-label="Yes"
        block
        @submit="banWithdrawalAccount"
        @cancel="banWithdrawalAccountVisible = false"
      />
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import FormBuilder from '@/layouts/components/FormBuilder.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table, FormBuilder,
  },
  data() {
    return {
      selectedProfile: '',
      sortOptions: [
        // {
        //   key: '', direction: 'desc', label: 'Newest', default: true,
        // },
        // { key: '', direction: 'asc', label: 'Oldest' },
      ],
      actions: [
        'view',
        {
          type: 'other',
          children: [
            {
              label: 'Ban Withdrawal Account',
              event: data => this.handleBan(data),
              visibility: true,
            },
          ],
        },
      ],
      selectedWithdrawalAccount: null,
      banWithdrawalAccountVisible: false,
      setBanField: [
        {
          key: 'comment',
          label: 'Comment',
          type: 'textarea',
          minLength: 1,
        },
      ],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('withdrawalAccount/getTableData')
    },
    loadDetail(id) {
      return this.$store.dispatch('withdrawalAccount/getDetail', id)
    },
    resetDetail() {
      this.$store.commit('withdrawalAccount/SET_DETAIL_DATA', undefined)
    },
    changeFields(val) {
      this.$store.commit('withdrawalAccount/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('withdrawalAccount/RESET_TABLE_FIELDS')
    },
    filterData(val) {
      this.$store.commit('withdrawalAccount/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('withdrawalAccount/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('withdrawalAccount/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('withdrawalAccount/SET_TABLE_KEYWORD', val)
    },
    handleBan(val) {
      this.selectedWithdrawalAccount = val.item
      this.banWithdrawalAccountVisible = true
    },
    banWithdrawalAccount(data) {
      const payload = {
        comment: data?.comment,
        owner_user_id: this.selectedWithdrawalAccount.owner_user_id,
      }

      this.$store.dispatch('withdrawalAccount/banWithdrawalAccount', payload)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Account has been banned successfully.',
              variant: 'success',
            },
          })

          this.banWithdrawalAccountVisible = false
          this.loadData()
        })
        .catch(error => {
          console.error('Error banning account:', error) // eslint-disable-line no-console

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: 'Failed to ban account. Please try again later.',
              variant: 'danger',
            },
          })

          this.banWithdrawalAccountVisible = false
          this.loadData()
        })
    },
  },
}
</script>
